.service-scroll-navigation-area {
  .section {
    .container {
      .pr-8 {
        @media screen and (max-width: 575px) {
          padding-right: 8px;
        }
      }
      .pl-8 {
        @media screen and (max-width: 575px) {
          padding-left: 8px;
        }
      }
    }
  }
}
.video-view {
  padding: 15px !important;
  background: #ecf2f6 !important;
  @media screen and (max-width: 575px) {
    padding: 8px !important;
    border-radius: 10px;
  }
  h5 {
    margin-bottom: 10px;
    color: #ffffff;
    background: #525260;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 23px;
    @media screen and (max-width: 575px) {
      font-size: 14px;
      margin-bottom: 8px;
      padding: 2px 10px;
    }
  }

  video {
    border-radius: 20px;
    display: block;
    max-height: 450px;
    background-color: #fff;
    @media screen and (max-width: 1199px) {
      max-height: 315px;
    }
    @media screen and (max-width: 991px) {
      max-height: 225px;
    }
    @media screen and (max-width: 767px) {
      max-height: 157px;
    }
    @media screen and (max-width: 575px) {
      max-height: 114px;
      border-radius: 8px;
    }
    @media screen and (max-width: 425px) {
      max-height: 95px;
    }
    @media screen and (max-width: 375px) {
      max-height: 75px;
    }
  }
}
.active-dark-mode {
  .video-view {
    video {
      background-color: #212121;
    }
  }
}
.video-kyc-done {
  padding: 50px 0px !important;
  background: #ecf2f6 !important;
  width: 100%;
  border-radius: 15px;
  h6 {
    margin: 0px;
  }
}

.meeting-btn {
  padding: 10px 25px !important;
  .recording-btn {
    animation: blink 1s infinite;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
} 
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #5956e9;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
